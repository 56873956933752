(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tb-content/page-footer/helpers/footer-handlebars-helper.js') >= 0) return;  svs.modules.push('/components/tb-content/page-footer/helpers/footer-handlebars-helper.js');

(function() {
  'use strict';

  function registerHelpers(hbs) {
    hbs.registerHelper('partnerColClasses', function(idx, len) {
      var count = idx + 1;
      var smClass;
      var mdClass;
      var smMaxLogosPerRow = 3; 
      var sm = len % smMaxLogosPerRow;
      var mdMaxLogosPerRow = 6; 
      var md = len % mdMaxLogosPerRow;

      if (count > len - sm) {
        smClass = 'col-sm-' + (12 / sm).toString();
      } else {
        smClass = 'col-sm-' + (12 / smMaxLogosPerRow).toString();
      }

      if (count > len - md) {
        mdClass = 'col-md-' + (12 / md).toString();
      } else {
        mdClass = 'col-md-' + (12 / mdMaxLogosPerRow).toString();
      }

      return 'col-xs-12 ' + smClass + ' ' + mdClass;
    });
  }

  if (typeof exports === 'object') {
    registerHelpers(require('hbs'));
  } else {
    registerHelpers(Handlebars);
  }
})();


 })(window);