(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tb-content/page-footer/assets/javascripts/scroll-stick.js') >= 0) return;  svs.modules.push('/components/tb-content/page-footer/assets/javascripts/scroll-stick.js');
"use strict";


svs.components = svs.components || {};
svs.components.tbContent = svs.components.tbContent || {};
svs.components.tbContent.pageFooter = svs.components.tbContent.pageFooter || {};
svs.components.tbContent.pageFooter.ScrollStick = class ScrollStick {
  constructor(el, container) {
    this.container = container || document.getElementsByClassName('sticky-footer-container')[0];
    this.el = el || document.getElementsByClassName('sticky-footer-el')[0];
    this.prevScrollPos = window.pageYOffset || document.documentElement.scrollTop;
    this.currentScrollPos = 0;
    if (this.el && this.container) {
      window.requestAnimationFrame && window.requestAnimationFrame(() => this.scrollUpdate());
      window.addEventListener('scroll', this.onScroll.bind(this), {
        passive: true
      });
      this.positionEL(this.prevScrollPos);
    }
    this.el.classList.add('page-footer-fixed-bottom');
  }
  scrollUpdate() {
    this.prevScrollPos !== this.currentScrollPos && this.positionEL(this.currentScrollPos);
    this.raf = window.requestAnimationFrame(() => this.scrollUpdate());
  }
  onScroll() {
    this.currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;
  }
  positionEL(scroll) {
    if (this.containerIsScrolledIntoView()) {
      this.el.classList.remove('page-footer-fixed-bottom');
    } else {
      this.el.classList.add('page-footer-fixed-bottom');
    }
    this.prevScrollPos = scroll;
  }
  containerIsScrolledIntoView() {
    return window.innerHeight >= this.container.getBoundingClientRect().bottom;
  }
  destroy() {
    this.el && this.el.classList.remove('page-footer-fixed-bottom');
    this.raf && cancelAnimationFrame(this.raf);
    window.removeEventListener('scroll', this.onScroll);
  }
};

 })(window);