(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tb-content/page-footer/views/footer_selfservice.js') >= 0) return;  svs.modules.push('/components/tb-content/page-footer/views/footer_selfservice.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.tb_content=_svs.tb_content||{};
_svs.tb_content.page_footer=_svs.tb_content.page_footer||{};
_svs.tb_content.page_footer.templates=_svs.tb_content.page_footer.templates||{};
svs.tb_content.page_footer.templates.footer_selfservice = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<footer class=\"page-footer bg-grey-700 padding-xs-v-2 padding-sm-v-4 animate animate-show\">\n  <div class=\"grid-row gutter-xs-3 gutter-sm-4 page-footer-max-width fc-white align-left\">\n    <div class=\"col-xs-12 col-sm-6 col-md-3 margin-sm-bottom-3 margin-md-bottom-0\">\n      <div class=\"padding-xs-h-1 padding-xs-bottom-4 padding-sm-v-0 padding-md-h-2\">\n        <span class=\"block margin-xs-bottom-1\">\n          "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"alt":"Svenska Spel","emblem":true,"brand":"svenskaspel-twolines-neg"},"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":6,"column":89}}}))
    + "\n        </span>\n        <p>\n          AB Svenska Spel\n          <br />\n          Org.nummer: 556460-1812\n          <br />\n        </p>\n        <p>\n          <strong>\n            Kontakta oss\n          </strong>\n          <br />\n          Telefon: 0770-11 11 11\n          <br />\n          E-post: kundservice.tur@svenskaspel.se\n        </p>\n      </div>\n    </div>\n    <div class=\"col-xs-12 col-sm-6 col-md-3 margin-sm-bottom-3 margin-md-bottom-0\">\n      <div class=\"padding-xs-h-1 padding-xs-bottom-4 padding-sm-v-0 padding-md-h-2\">\n        <span rel=\"noopener\" class=\"block margin-xs-bottom-1\">\n          <img class=\"\" src=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,"/images/tb-content/page-footer/spelinspektionen.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":28,"column":29},"end":{"line":28,"column":98}}}))
    + "\" alt=\"Logotype för Spelinspektionen\" />\n        </span>\n        <p>\n          Spelinspektionen är tillsynsmyndighet.\n          <br />\n          Licensen från Spelinspektionen gäller från och med den 1 januari 2019 till och med den 31 december 2028.\n        </p>\n      </div>\n    </div>\n    <div class=\"clearfix visible-sm-block\"></div>\n    <div class=\"col-xs-12 col-sm-6 col-md-3 margin-sm-bottom-3 margin-md-bottom-0\">\n      <div class=\"padding-xs-h-1 padding-xs-bottom-4 padding-sm-v-0 padding-md-h-2\">\n        <p class=\"margin-xs-bottom-0\">\n          <span rel=\"noopener\" class=\"inline-block\">\n            <span class=\"footer-18-logo\">\n              "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"symbols",{"name":"ui","hash":{"alt":"Spel för dig över 18 år","class":"align-center inline-block","name":"18-logo-neg"},"data":data,"loc":{"start":{"line":43,"column":14},"end":{"line":43,"column":113}}}))
    + "\n            </span>\n          </span>\n        </p>\n        <p class=\"margin-xs-bottom-2\">\n          <b>\n            Spel för dig över 18 år\n          </b>\n        </p>\n        <p class=\"margin-xs-bottom-0\">\n          <span rel=\"noopener\" class=\"inline-block\">\n            <img class=\"\" src=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,"/images/tb-content/page-footer/stodlinjen.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":54,"column":31},"end":{"line":54,"column":94}}}))
    + "\" alt=\"Logotype för Stödlinjen\" />\n          </span>\n        </p>\n        <p>\n          <b>\n            Spelar du för mycket?\n          </b>\n          <br />\n          Ring Stödlinjen: 020-81 91 00\n        </p>\n      </div>\n    </div>\n    <div class=\"col-xs-12 col-sm-6 col-md-3 margin-sm-bottom-3 margin-md-bottom-0\">\n      <div class=\"padding-xs-h-1 padding-xs-bottom-4 padding-sm-v-0 padding-md-h-2\">\n        <p class=\"padding-xs-0 margin-xs-0\">\n          © AB Svenska Spel\n        </p>\n        <p class=\"padding-xs-0 margin-xs-0\">\n          Denna webbplats är skyddad av upphovsrättslagen (1960-729).\n        </p>\n        <p class=\"padding-xs-0 margin-xs-0\">\n          Detta omfattar varumärken, text, fotografier, teckningar och bilder.\n        </p>\n      </div>\n    </div>\n  </div>\n</footer>\n";
},"useData":true});
Handlebars.partials['components-tb_content-page_footer-footer_selfservice'] = svs.tb_content.page_footer.templates.footer_selfservice;
})(svs, Handlebars);


 })(window);